import { Box } from "@mui/material"
import { Icon } from "assets/Icons_";
import Dropdown from "components/mui/Dropdown"
import DropdownItem from "components/mui/DropdownItem";
import { BREAK_ } from "constants";
import { useBreak } from "hooks/useBreak";
import { useRef } from "react";
import { getFocus, useGet } from "state/jotai";
import { getIsoData, getSeasonIsoData, setSeasonIsoData } from "state/jotai.isometryDataHooks";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { _seasons_isometry } from "state/store.season";
import styled from "styled-components";

export const SelectWeatherMobile = () => {
    const dropdownRef = useRef(null);
    const isDesktop   = useBreak("md_up");
    const { pin, pid } = useGet(active_project);
    const activeIsometryFrame   = getIsoData("activeframe");
    const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

    const season  = getFocus(_seasons_isometry, 'season_type');
    const seasonType = season?.[pin]?.[pid]?.[activeIsometryFrame]?.type;

    const weather     = getSeasonIsoData("season_type");
    const setWeather  = setSeasonIsoData("season_type");
    const weatherType = weather?.[activeIsometryFrame]?.type;

    const handleWeatherSelect = (w) => {        
        setWeather({...weather, [activeIsometryFrame]: {type: w}});
    };


    return <Select>
        <Box>
            <Dropdown
                width      = {isDesktop ? "300px" : "auto"}
                ref        = {dropdownRef}
                buttonText = {buttonsLabel?.filter( btn => btn.UILabelProperty === "Vaelgsaeson")[0]?.Label}
                // selectedLabel = {isDesktop && active?.label}
                containerStyle = {{ left: "0 !important", marginLeft: "0 !important" }}
            >
                {options.map((option, i) => (
                <DropdownItem
                    key      = {i}
                    onClick  = {() => handleWeatherSelect(option.Label)}
                    selected = {option.Label === seasonType}
                    style={{ textTransform: "capitalize", gap: "8px" }}
                >
                    <Icon icon     = { option.icon_name } color = {"#000"} />
                    { buttonsLabel?.filter( btn => btn.UILabelProperty === option.id)[0]?.Label }
                </DropdownItem>
                ))}
            </Dropdown>
        </Box>
    </Select>
}


export const Select = styled.div`
  display        : flex;
  align-items    : center;
  justify-content: center;
  cursor         : pointer;
  font-size      : 1.125rem;
  gap            : 8px;
  padding        : 0 20px;

  ${BREAK_.md_up} {
    color: #fff;
  }
`


const options = [
    {
        icon_name: "spring",
        Label: "spring",
        id: "Efterar"
    },
    {
        icon_name: "sun",
        Label: "summer",
        id: "Sommer"
    },
    {
        icon_name: "cloud",
        Label: "fall",
        id: "Forar"
    },
    {
        icon_name: "winter",
        Label: "winter",
        id: "Vinter"
    }
]