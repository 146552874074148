import { LottiPlay } from "components/LottieAnimation/LottiePlay";
import { useCachedImages } from "hooks/useCachedImages";
import { useAtom } from "jotai";
import { useState } from "react";
import { cacheImg, prev_img } from "state/store.global";

export const ImgWrapper = ({ img, func, style, alt, loader, onClick }) => {
  
  const [pImg, setP_img]             = useAtom(prev_img)
  const [imgSrc, setImgSrc] = useState('')
  const [loading, setLoading]        = useState(true)
  const cachedImage                  = useCachedImages(img);

  const imageLoaded                  = () => setLoading(false)
  
  // useEffect(()=> {
  //   let timeout;
  //   if(imgSrc !== img) setLoading(true)
  //   else if (imgSrc === img) {
  //     timeout = setTimeout(()=> setLoading(false), 1500) 
  //   }
  //   setImgSrc(img)
  //   return () => clearTimeout(timeout)
  // }, [img, imgSrc])

  return (
      <>
        <img
          style = {{ display: !cachedImage?.src ? 'none' : 'unset',
            ...style }}
          src   = {cachedImage?.src}
          // src={imgSrc}
          loading = "lazy"
          alt     = {alt}
          // onLoad  = {()=> {
          //   imageLoaded();
          //   func();
          // }}
          onClick = {onClick}
      />
      { loader && 
      // loading
      !cachedImage?.src 
      && (
          <div
            style={{
              display       : 'flex',
              justifyContent: 'center',
              alignItems    : 'center',
              height        : "100%"
            }}
            onClick = {onClick}
          >
            <LottiPlay style = {{ height: "100%" }} loop = {true} />
          </div>
        )}
      </>
    )
}


  // default props
ImgWrapper.defaultProps = {
    func   : () => {},
    onClick: () => {},
    alt    : '',
    loader : true
};
 