import { get, useAtom, useGet, getFocus }   from 'state/jotai'
import { _isometry }      from 'state/store.isometry'
import { _subiso }        from 'state/store.subiso'
import { vis_filter, vis_desktop_nav, seasonMode }     from 'state/store.global'
import { toggle_drawer }  from 'state/actions'

import { Dropdown2 }       from 'components/Dropdown2/Dropdown2New'
import { Mode360 }        from 'components/IsometryRotation/360'
import { Sort }           from 'components/Sort2/Sort'
import { Back }           from 'components/Isometry/Isometry.Back'

import { Icon }           from 'assets/Icons_'
import { useLocation }    from 'react-router-dom'
import { Nav, Filter }    from './TopMenu.style'
import { useParams, useNavigate }      from 'react-router-dom'
import LanguageMenu       from 'components/LanguageMenu/LanguageMenu'
import { useBreak }       from 'hooks/useBreak'
import NavItems           from 'components/Nav/NavItems'
import { iso_free, active_project }       from 'state/store.global'
import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'
import { _project } from 'state/store.projects'
import { current_language, previousUrl } from 'state/store.global'

    // FAVORITES
import ToolbarFavorites from 'components/Favorites/Toolbar'
import WeatherMode from 'components/SunDiagram/WeatherMode'
import { SelectWeatherMobile } from 'components/SunDiagram/SeletWeatherMobile'

export const TopMenu = ({left,right,nav}) => {
  const [url] = useAtom(previousUrl);

  const navigate        = useNavigate();
  const rmode           = useAtom(iso_free)[0]
  const isDesktop       = useBreak('md_up')
  const sub             = useParams('sub').sub
  const frames1         = get(_isometry, 'frames')
  const frames2         = get(_subiso, 'frames')?.find(i => i.NAME == sub)?.RES
  const [, setShowMenu] = useAtom(vis_desktop_nav);
  const sMode      = useGet(seasonMode);
  const clickFilter     = () => {toggleDrawer('filter'); setShowMenu(false);}
  const isFilter        = useAtom(vis_filter)[0]
  const toggleDrawer    = useAtom(toggle_drawer)[1]
  const filter          = { size:18, icon:'filters', color: isDesktop ? clr.icons : "#000" }
  const here            = useLocation().pathname
  
  const showAt = ['isometry','list','favorites', 'gallery','downloads']
                       .some(i => here.includes(i))
  const hideAt = ['unit','compare']
                       .some(i => here.includes(i))
  const hideAtMobile = ['gallery','downloads']
                       .some(i => here.includes(i)) && !isDesktop
  const atModel     = here.includes('isometry')
  const atList      = here.includes('list')
  const atFavorites = here.includes('favorites')
  const atUnit      = here.includes('unit')
  const atCompare   = here.includes('compare')

  const hideFilterMultiActiveFalse = ['compare', 'gallery',
  'downloads', 'unit'].some(i => here.includes(i))

  const doesRotate = ( sub ? frames2?.length : frames1?.length ) > 10

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  if ( showAt || hideAt ) return (
    <>
     { (!hideAt || isDesktop) && (!hideAtMobile) && <Nav clr={clr} left={left} right={right} nav={nav} layout>
        {/* FAVORITES ONLY */}
        {(atFavorites && !isDesktop) ? (
          <>
            <ToolbarFavorites />
          </>
        ) : (
          <>
            <span>
            { (!isDesktop && sMode && atModel)  ? !hideFilterMultiActiveFalse && (
              <> 
              <SelectWeatherMobile />
              </>
              )
              : 
              <>
              { !(atUnit || atCompare) &&
                <Filter onClick = {clickFilter}>
                  <Icon {...filter} />
                    Filter
                </Filter>
              }
              </> 
            }
              
             { (isDesktop && (atUnit || atCompare)) && <Box color={clr.primary_text} onClick={()=> navigate(url, { replace: true })}> 
                <Icon icon = 'back' size = {'18'} color = {clr.primary_text} />
                <Text>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Tilbage')[0]?.Label }</Text>
              </Box> } 
              {atModel && rmode && <Back />}
            </span>
            <span>
              {/* { atModel && <Dropdown2 /> } */}
              {atList && 
              <div> 
              </div>}
            </span>
       
            <span>
            {atModel && <WeatherMode />}
              {atModel && <Mode360 />}
              {!isDesktop && atList && <Sort />}
              {isDesktop && <NavItems hideNav={false}/> }
            </span>
          </>
        )}
      </Nav> }
    </>
  );
  return false

}

const Box = styled.div`
  gap        : 5px;
  cursor     : pointer;
  padding    : 5px 15px;
  z-index    : 5;
  align-items: center;
  display    : flex;
  gap        : 5px;
  user-select: none;
  color      : ${({color})=>color};
  ${ BREAK_.md_up } {
    position: absolute;
        // top: 105px;
        // left: 40px;
    top    : 20px;
    left   : 20px;
    padding: 0;
  }
`
const Text = styled.div`
  white-space: nowrap;
  font-size  : 18px;
  font-weight: 400px;
      /* border: 1px solid black; */
`