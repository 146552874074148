import moment from "moment";

export const timeInterval = (start_time, time_gap, intervalIndex, time_varient, format) => {

    // starting time
    const startingTime = moment(start_time, format);

    // Assuming a half-hour interval between frames
    const timeInterval = moment.duration(time_gap, time_varient);

    // Calculate total time
    const totalTime = startingTime.add(intervalIndex * timeInterval);

    // Format the result
    const endTime = totalTime.format(format);

    return endTime
}