import { useReset } from "components/Filter/Filter.Reset";
import { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { close_drawer } from "state/actions";
import { useAtom } from "jotai";
import { useIdle } from "react-use";
import { useNavigate } from "react-router-dom";
// import KeyboardReact from "react-simple-keyboard";
import {
  inactiveActions,
  current_language,
  currentActiveProject,
  vis_desktop_nav,
  kioskMode,
  isUnitContactDrawer,
  isOpenContactDrawer,
  active_project,
} from "state/store.global";
import { getFocus, setFocus, useFocusGet, useGet } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { fn3 } from "utilities/utility.misc";
import { initialsort as isr } from "utilities/utility.sort";
import { _project } from "state/store.projects";
import { useRef } from "react";

export const Kiosk = () => {
  const type = useFocusGet(_bootstrap, "type");
  const getKioskMode = useGet(kioskMode);
  const closeDrawer = useAtom(close_drawer)[1];

  const [keyboardVisibility, setKeyboardVisibility] = useState(getKioskMode);
  const [layout, setLayout] = useState("default");
  const [input, setInput] = useState("");
  const keyboard = useRef();

  const navigateTo = useNavigate();
  const reset = useReset({ global: false });
  const doInactiveActions = useAtom(inactiveActions)[1];
  const currentLanguage = useAtom(current_language)[0];
  const setActiveProject = useAtom(currentActiveProject)[1];
  const [, setShowMenu] = useAtom(vis_desktop_nav);
  const setIsOpen = useAtom(isOpenContactDrawer)[1];
  const sorterOptions = getFocus(_project, "sorteroptions");
  const setDefaultsort = setFocus(_project, "activesorting");
  const { pin, pid } = useGet(active_project);

  // const onChange = input => {
  //   setInput(input);
  //   console.log("Input changed", input);
  // };
  // const onKeyPress = button => {
  //   console.log("Button pressed", button);
  //   if (button === "{shift}" || button === "{lock}") handleShift();
  // };

  const onIdle = () => {
    setIsOpen(false);

    reset();
    closeDrawer("detail");
    setShowMenu(false);
    doInactiveActions();
    setActiveProject("");
    if (type === "mono")
      setDefaultsort([{ [pid]: isr(sorterOptions?.[pin]?.[pid]) }]);
    else
      setDefaultsort(a => fn3(a, pin, pid, isr(sorterOptions?.[pin]?.[pid])));
    navigateTo(`/`);

    setKeyboardVisibility(true);
  };
  const {} = useIdleTimer({
    onIdle,
    timeout: 1 * 60000,

    disabled: !getKioskMode, // To disable idle functionality SET to TRUE
  });
  //console.log(keyboardVisibility, "visibility");
  // return (
  //   <div>
  //     {/* ... (existing code) */}

  //     {/* Add the conditional rendering for the KeyboardReact component here */}
  //     {keyboardVisibility && (
  //       <KeyboardReact
  //         keyboardRef={r => (keyboard.current = r)}
  //         layoutName={layout}
  //         onChange={onChange}
  //         onKeyPress={onKeyPress}
  //       />
  //     )}
  //   </div>
  // );
};
