import { useEffect, useState }  from 'react'
import { useFocusGet, useAtom } from 'state/jotai'

import { _bootstrap }           from 'state/store.bootstrap'
import { _project }             from 'state/store.projects'
import { _isometry }            from 'state/store.isometry'
import { _subiso }              from 'state/store.subiso'

import { array } from 'utilities/utility.misc'
import { _seasons_isometry } from 'state/store.season'
import { getIsoData } from 'state/jotai.isometryDataHooks'

export const useProjectSetup = () => {

  const [ res, setRes ] = useState(false)
  
  const [ isometry, setIsometry ] = useAtom(_isometry)
  const [ seassonIsometry, setSeassonIsometry ] = useAtom(_seasons_isometry)
  const [ project , setProject  ] = useAtom(_project)
  const [ subiso  , setSubiso   ] = useAtom(_subiso)
  const config = getIsoData("config");

  const _x = {}
  const _y = {}
  const _z = {}
  const _zy = {}
  
  const ARR = useFocusGet(_bootstrap, 'projectids')
  const NUM = ARR?.length || 0
  const BAS = array(NUM, x => ({ [ARR[x]]:[] }) )


  useEffect(()=>{
    if ( BAS.length > 0) {

      Object.keys(isometry).forEach( x => _x[x] = BAS )
      Object.keys(project ).forEach( y => _y[y] = BAS )
      Object.keys(subiso  ).forEach( z => _z[z] = BAS )
   
      setIsometry(_x)
      setProject (_y)
      setSubiso  (_z)

      setRes(true)

    }
  },[ARR])

  useEffect(()=> {
    if(config) {
      let angleType = {}
      const SEASONBAS = array(NUM, x => {
        const frames = {}
        config?.IMAGE_SNAPFRAMES.forEach((i_s) => {
          frames[i_s] = [];
          angleType = {
            ...angleType,
            [i_s]: {type : "summer"}
          }
        })
        return { [ARR[x]]: frames }
      } )

      Object.keys(seassonIsometry).forEach( zy => _zy[zy] = SEASONBAS )
      ARR.forEach((pid, pin) => {
        _zy.season_type[pin][pid] = angleType
      })
      
      setSeassonIsometry(_zy)
    }
  }, [ARR, config])
  
  return { res }
  
}