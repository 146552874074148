import { useEffect, useState } from 'react'
import { Main, Control, Text } from './NoSnapSlider.style'
import { Track } from 'components/SliderTrack/SliderTrack'
import { getFocus, useGet, useSet } from 'state/jotai'
import { active_project, iso_isrotating } from 'state/store.global'
import { vis_overlay } from 'state/store.global'
import { motion } from 'framer-motion'
import { _project } from 'state/store.projects'
import { SeasonSliderThumb } from 'components/SliderThumb/SeasonSliderThumb'
import moment from 'moment'
import { timeInterval } from 'utilities/utility.timeInterval'
import { useBreak } from 'hooks/useBreak'

export const SeasonsNoSnapSlider = ({ frame, setFrame, config, index, setIndex }) => {
  const isMobile = useBreak('md_dn')

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3, ease: 'linear' },
  }

  const ini = config.FIRST_IMAGE
  const max = config.FIRST_IMAGE + config.IMAGE_NUM - 1
  const end = config.IMAGE_NUM - 1
  const marks = config.IMAGE_SNAPFRAMES
  
  const sliderIndex = frame - ini
  // Number of frames
  const numberOfFrames = max - ini;
  const totalSec = numberOfFrames/10;
   
  const [render, setRender] = useState(false)
  const setOverlay = useSet(vis_overlay)
  const setRotate = useSet(iso_isrotating)
  const change = v => setFrame(v)

  useEffect(() => {
    let timeout = setTimeout(() => {
      setRender(true)
    }, 2000)
    setOverlay(false)
    return () => {
      setOverlay(true)
      clearTimeout(timeout)
    }
  }, [])

  const endTime = timeInterval('08:00 AM', 30, (end+1)/30, 'minutes', 'H:mm');

  if (!isNaN(end))
    return (
      <Main
        {...animation}
        onTouchStart={() => setRotate(false)}
        onTouchMove={() => setRotate(false)}
        // onMouseDown        = {()=>setRotate(false)}
        // onClick            = {()=>setRotate(false)}
        onMouseDownCapture={() => setRotate(false)}
      >
        { render && (
          <motion.div {...animation} style={{width: isMobile ? '70%' :'100%', display: "flex", gap: isMobile ? "10px" : "16px"}}>
            <div style={{display: "flex", alignItems: "end", width:'15%', justifyContent: "center", flexDirection: "column"}}>
                <div style={{ fontWeight: "500", fontSize: "14px", lineHeight: "18px" }}>
                    <div style={{ fontWeight: "600" }}>{buttonsLabel?.filter( btn => btn.UILabelProperty === 'Solopgang')[0]?.Label}:</div>
                    <div style={{ textAlign: "center" }}>08:00</div>
                </div>
            </div>
            <Control
              value={frame}
              defaultValue={frame}
              renderTrack={Track}
              renderThumb={(props)=> <SeasonSliderThumb props={props} index={sliderIndex} />}
              step={1} 
              max={max}
              min={ini}
              marks={[...marks]}
              onSliderClick={() => setRotate(false)}
              onChange={change}
            />
            <div style={{display: "flex", alignItems: "start", width:'15%', justifyContent: "center", flexDirection: "column"}}>
                <div style={{ fontWeight: "500", fontSize: "14px", lineHeight: "18px" }}>
                    <div style={{ fontWeight: "600" }}>{buttonsLabel?.filter( btn => btn.UILabelProperty === 'Solnedgang')[0]?.Label}:</div>
                    <div style={{ textAlign: "center" }}>{endTime}</div>
                </div>
            </div> 
          </motion.div>
        )}
      </Main>
    )
}
