import { Box } from "@mui/material";
import React from "react";
import {
  Buttons,
  DrawerCTA,
  Gallery,
  Highlights,
  Price,
  TopDrawer,
  useActiveUnit,
} from ".";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import { getFocus, useAtom, useGet } from "state/jotai";
import { get } from "components/Favorites/Favorites.Utilities";
import { _drawer, active_project, drawer_animation_done } from "state/store.global";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { useBreak } from "hooks/useBreak";
import { _project } from "state/store.projects";

function UnitDrawer() {
  const { pin, pid } = useGet(active_project)
  const drawer   = useAtom(_drawer)[0]  
  const isDetail = drawer.detail
  const unit = useActiveUnit();
  const highlights = useUnitHighlight(unit);
  const unitId = parseInt(unit.getId());
  const status = unit.getStatus();
  const address = unit.getAddress();
  const townAndZipcode = unit.getTownZipCode();
  const images =  isDetail ? unit.getImages() : [];
  const thumbnails = isDetail ? unit.getThumb() : [];
  const isRent = unit.isRent();
  const price = unit.getPrice();
  const Pris = unit.getPris();
  const unitNo = unit.getProjectNo()
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  
  const availableUnits = unit?.data?.Data?.UnitStatusID?.Value === 10;
  const availablePris = unit?.data?.Data?.Pris_Tekst_DK?.Value === "";

  const isDrawerAnimating = useAtom(drawer_animation_done)[0];
  const isMobile = useBreak('md_dn')
  // console.log(price,isRent, '[ric')
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        // pb: 5,
        backgroundColor: "#ffffff",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between"

      }}
    >
      <Box>
      <TopDrawer
        address={`${address}, ${townAndZipcode}`}
        statusText={status.label}
        backgroundColor={status.color}
        textColor="#ffffff"
      />

      {/* {isDrawerAnimating && <Gallery images={images} thumbImg={thumbnails} UnitDrawer />} 325px*/}
      <Gallery images={images} thumbImg={thumbnails} UnitDrawer height = {isMobile ? '294px' : '321px'} />
      <Buttons unitId={unitId} />

      <OpenHouse />
      <Box sx={{ mb: { md: 1, xs: 2 }, mt: { md: 2, xs: 2 }, textAlign: "center" }}>
        <div>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Bolignr')[0]?.Label }</div>
        <Box fontWeight="bold" fontSize={20} mt={0.75}>
          {unitNo}
        </Box>
        </Box>
      <Box sx={{ px: 2.5, mt: { md: 3.5, xs: 2 } }}>
        <Box sx={{ mb: { md: 3.5, xs: 2 }, mx: "auto", maxWidth: 350 }}>
          <Highlights items={highlights} />
        </Box>
        <Box sx={{ mb: { md: 5.5, xs: 2 }, mt: { md: 5, xs: 2 }, textAlign: "center" }}>
          <Price
            label={isRent ? `${ get(unit.data,'LejelejlighedsText') }` : "Kontatpris"}
            value={price}
          />
        </Box>

        <DrawerCTA unit={unit} />
      </Box>
      </Box>
      <PoweredBy />
    </Box>
  );
}

export default UnitDrawer;
