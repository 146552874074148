import { useState } from "react";
import { Backdrop } from "styles/styles.backdrop";
import { getFocus, useAtom, useGet } from "state/jotai";
import { Box, Button, Stack } from "@mui/material";
import { DEPOSITECALCULATIONS, MOVEINCALCULATIONS, STATICS } from "api/api";
import { Icon } from "assets/Icons_";
import styled from "styled-components";
import {
  active_project,
  vis_calculate_benefit,
  vis_deposite,
  vis_movein_price,
} from "state/store.global";
import { housing_benefit } from "state/store.global";
import { gross_income } from "state/store.global";
import {
  Container,
  Inner,
  Result,
  SubText,
  CurrencyInput,
} from "./HousingBenefit.style";
import "./HousingBenefit.css";
import { useHousingBenefit } from "./HousingBenefit.hook";
import { _project } from "state/store.projects";
import { useFetch } from "hooks/fetch/useFetch";
import { useActiveUnit } from "components/Unit";
import { _bootstrap } from "state/store.bootstrap";

const data = {
  Header1: "Beregn din boligstøtte",
  Text1  : 
    "Indtast din husstands årlige bruttoindtægt (månedsløn før skat x 12), og få lavet en vejledende beregning for din månedlige boligstøtte",
  Header2: "Husstandens årlige bruttoindtægt",
  Header3: "Din vejledende boligstøtte",
  SubText: 
    "Den boligstøtte beregning du kan se her, er vejledende og vi giver ingen garanti i forhold til den endelige boligstøtte du måtte kunne få. Brug eventuelt https://www.boligstoette.dk/ som har en beregner der stilles til rådighed af Udbetaling Danmark.",
};

export const DepositePortal = () => {
  const setDepositePrice = useAtom(vis_deposite)[1];

  const type = useGet(_bootstrap).type

  const mono = type === 'mono'
  const file = mono ? 'main/ProjectData.jsx' : ''
 
  const unit         = useActiveUnit();
  const { pin, pid } = useGet(active_project);
  
  const MPC  = pid ? DEPOSITECALCULATIONS(pid,unit?.data?.UnitID)  : null
  const MIPC = useFetch({url:mono?MPC:null,file}).D                        /*getting the data for deposite price calculations */
 
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const icon = {
    icon : "close6",
    color: "#fff",
    wrap : { position: "absolute", top: 20, right: 20 },
    size : "20",
    click: () => setDepositePrice(false),
  };

  return (
    <>
      <Backdrop
        onClick={() => {
          setDepositePrice(false);
        }}
      />
      <Container>
        <img src = {`${STATICS}generic.png`} alt = "" />
        <Icon {...icon} />
        <Inner>
          <Box sx = {{ height: 10 }} />
          <h2>
            {
              buttonsLabel?.filter(
                btn => btn.UILabelProperty === "WhatIsSecurityDeposit"
              )[0]?.Label
            }
          </h2>
          <Box sx = {{ height: 10 }} />
          <p>
            {
              buttonsLabel?.filter(
                btn => btn.UILabelProperty === "DepositCalculationDescription"
              )[0]?.Label
            }
          </p>
          <Box sx = {{ height: 30 }} />
          <Box>
            <div>
              <Row>
                <div>{
              buttonsLabel?.filter(
                btn => btn.UILabelProperty === "Rent"
              )[0]?.Label
            }</div>
                <div>{MIPC?.rent} kr.</div>
              </Row>
              <Box sx = {{ height: 5 }} />
              <Line />
              <Box sx = {{ height: 5 }} />
              <Row>
                <div>{
              buttonsLabel?.filter(
                btn => btn.UILabelProperty === "NumberOfMonthInDeposit"
              )[0]?.Label
            }</div>
                <div>{MIPC?.deposit_number_of_month }  { MIPC?.deposit_number_of_month > 1 ? buttonsLabel?.filter(
                btn => btn.UILabelProperty === "Months"
              )[0]?.Label : buttonsLabel?.filter(
                btn => btn.UILabelProperty === "Month"
              )[0]?.Label}</div>
              </Row>
              <Box sx = {{ height: 5 }} />
              <Line />
              {/* <Row>
                <div>Depositum</div>
                <div>{MIPC?.deposit} kr.</div>
              </Row> */}
            </div>
            <div style = {{ height: 30 }} />

            <Row>
              <div style = {{ fontWeight: '600' }}>
              {
              buttonsLabel?.filter(
                btn => btn.UILabelProperty === "Deposit"
              )[0]?.Label
            }
              </div>
              <div>
              <div style = {{ fontWeight: '600' }}>{MIPC?.deposit} kr.</div>
              </div>
            </Row>
            <Box sx = {{ height: 5 }} />
            <Line />
          </Box>
          <div   style     = {{ height: 30 }} />
          <Stack direction = "row" spacing = "1px" justifyContent = "center"></Stack>
        </Inner>
      </Container>
    </>
  );
};
export const Row = styled.div`
  padding        : 0.4rem 0;
  font-size      : 16px;
  font-weight    : 400;
  font-family    : Arial;
  display        : flex;
  justify-content: space-between;
  align-items    : center;
`;
export const Line = styled.div`
  min-height: 1px;
  max-height: 1px;
  width     : 100%;
  background: lightgray;
`;
