import React, { useState } from 'react'
import styled from 'styled-components'
import { useBreak } from 'hooks/useBreak'
import { BREAK_ } from 'constants'
import { getFocus, useAtom, useGet, useSet } from 'state/jotai'
import { active_project, iconRotate, iso_free, iso_isrotating, seasonMode, weaterMode } from 'state/store.global'
import { Icon } from 'assets/Icons_'
import { getIsoData, setIsoData } from 'state/jotai.isometryDataHooks'
import { SnapOnRotationEnd } from 'components/IsometrySnapOnRotationEnd/SnapOnRotationEnd'
import { _project } from 'state/store.projects'

const WeatherMode = () => {
    const isMobile = useBreak('md_dn')
    const rmode = useGet(iso_free)
    const isrotate = useGet(iso_isrotating)
    const setRmode = useSet(iso_free)
    const setIsrotate = useSet(iso_isrotating)
    const setSeasonMode = useSet(seasonMode);
    const setWeatherMode = useSet(weaterMode);
    const [iconRotated, setIconRotated] = useAtom(iconRotate)
    const config = getIsoData("config");
    const frame = getIsoData("activeframe");
    
    const setIndex = setIsoData("snapindex");
    const setFrame = setIsoData("activeframe");

    const { pin, pid } = useGet(active_project)
    const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

    const click1 = () => {
       // Toggle the weather mode state
        setSeasonMode(!iconRotated)
        // Toggle icon rotation
        setIconRotated(!iconRotated)
        setWeatherMode(!iconRotated)
      if(!iconRotated) {
        setIsrotate(true)
        setRmode(true)
      }else {
        setRmode(false)
        setIsrotate(false)
      }
    }

    return (
      <>
        {/* { ((isMobile && !rmode) || !isMobile) &&
          <Box onClick={click1}>
            { config && <SnapOnRotationEnd
              {...{ frame, setFrame, config, setIndex, rmode, isrotate }}
            />
            }
              <RotatingIcon icon='sun' size={isMobile ? '25' : '32'} color={'#000'} rotated={iconRotated} />
              <Text>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Soldiagram')[0]?.Label || "Sun Diagram"}</Text>
          </Box>
        } */}
      </>
    )
}

export default WeatherMode

const Text = styled.div`
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400;
`
const Box = styled.div`
  gap: 5px;
  cursor: pointer;
  padding: 5px 5px;
  z-index: 5;
  align-items: center;
  display: flex;
  ${BREAK_.md_up} {
    position: absolute;
    top: 95px;
    right: 250px;
    padding: 0;
    padding: 5px 15px;
  }
`

const RotatingIcon = styled(Icon)`
  transition: transform 0.3s ease-in-out;

  ${(props) => props.rotated && `
    transform: rotate(90deg);
  `}
`;
