import styled from 'styled-components/macro'
import { useState } from 'react'
import { timeInterval } from 'utilities/utility.timeInterval'
import { getFocus, useGet } from 'state/jotai'
import { active_project } from 'state/store.global'
import { _project } from 'state/store.projects'

export const SeasonSliderThumb = ({props, index}, state) => {

  const fill = clr.buttons

  return (
    <StyledThumb key={index} {...props}>
      <SVG index={index}></SVG>
    </StyledThumb>
  )
}

const SVG = ({ index, children}) => {

  const [cursor, setCursor] = useState('grab')

  const endTime = timeInterval('08:00 AM', 1, index, 'minutes', 'H:mm');

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  return (
    <>
       <div style={{display: "flex", alignItems: "start", position: "absolute", top: "-42px", left: "-27px", justifyContent: "center", flexDirection: "column", color: "#000"}}>
            <div style={{ fontWeight: "500", fontSize: "14px", lineHeight: "18px" }}>
                <div style={{ fontWeight: "600" }}>{buttonsLabel?.filter( btn => btn.UILabelProperty === 'Tidspunkt')[0]?.Label}:</div>
                <div style={{ textAlign: "center" }}>{endTime}</div>
            </div>
        </div> 
      <Svg 
        viewBox={`0 0 100 100`}
        onMouseDown={()=>setCursor('grabbing')}
        onMouseUp={()=>setCursor('grab')}
        onMouseLeave={()=>setCursor('grab')}
        onMouseEnter={()=>setCursor('grab')}
        style={{cursor:cursor}}
        >
        { children }
      </Svg>
    </>

  )
}

const Svg = styled.div`
  width:3px;
  height:26px;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  background:#2A303F;
  border-radius:5px;
  /* z-index:0; */
`

const StyledThumb = styled.div`
  position:relative;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #fff;
`