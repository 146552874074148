import { useEffect } from 'react'
import { useState }  from 'react'
import { animate }   from 'framer-motion'
import { seasonMode } from 'state/store.global';
import { useGet } from 'state/jotai';

export const Play = ({frame, setFrame, config }) => {
  const isSeasonModeOn = useGet(seasonMode);

  const min = config.FIRST_IMAGE
  const max = config.LAST_IMAGE
  const num = config.IMAGE_NUM

  const [state, setState]     = useState()

  const from = frame;
  const to = max+(frame-min)
  // const [from, setFrom] = useState(frame)
  // const [to, setTo]     = useState(max+(frame-min))

  useEffect(()=>{
    const controls = animate(from, to, {
      duration   : isSeasonModeOn ? 5 : 10,
      ease       : 'linear',
      repeat     : Infinity,
      onUpdate   : v => {
        const val = Number(v.toFixed(0))
        const to = ( val > max ) ? val - num + 1 : val 
        // console.log({from, to})
        setFrame(to)
      } 
    })
    return () => {
      controls.stop()
    }
  },[from,to, config.IMAGE_NUM])

  return null
}