import styled                      from 'styled-components/macro'
import { useEffect, forwardRef, useState }   from 'react'

export const SeasonCanvas = forwardRef(({dim,frames,frame,pid,status,config},ref ) => {

  useEffect(()=>{
    if(frames?.length) {
      
      const ctx = ref?.current?.getContext('2d')
      const w   = dim.W
      const h   = dim.H
      const i   = frames.findIndex(i=>Object.keys(i)[0] == frame)
      const img = frames?.[i]?.[frame]
   
      if ( ctx && img?.src ) {
        ctx.canvas.width  = w
        ctx.canvas.height = h
        ctx.clearRect(0,0,w,h)
        ctx.drawImage(img,0,0,w,h)
      }
    }
  },[ config, frame, pid, frames, status, dim ])
  const ready = frames?.[config?.IMAGE_NUM-1]?.[config?.LAST_IMAGE]?.src

  return ( 
    <> 
      { ready &&
        <StyledCanvas 
          w   = {dim.W}
          h   = {dim.H}
          ref = {ref}/>
      } 
    </> 
  )

})

const StyledCanvas = styled.canvas`
  width     : ${x => x.w}px;
  height    : ${x => x.h}px;
  max-width : unset;
  background: transparent;
  position  : absolute;
`
