import { Isometry }    from 'components/Isometry/Isometry'
import { SeasonIsometry } from 'components/Isometry/SeasonIsometry';
import { useGet } from 'state/jotai';
import { seasonMode } from 'state/store.global';

export const ViewIsometry = () => {
  const isSeasonModeOn = useGet(seasonMode);

  return (
    <>
      <div style={{border:'0px solid gray',height:'100%',position:'relative'}}>
        { isSeasonModeOn ? <SeasonIsometry />  : <Isometry/> }
      </div>
    </>
  )
}