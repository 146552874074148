import { useEffect }              from 'react'
import { _bootstrap }             from 'state/store.bootstrap'
import { _project }               from 'state/store.projects'
import { active_project, currentSeason }         from 'state/store.global'
import { useFetch }               from 'hooks/fetch/useFetch'
import { useGet, setFocus, getFocus }       from 'state/jotai'
import { array }                  from 'utilities/utility.misc'
import { preloadImage }           from 'utilities/utility.images'
import { ISO_FRAMES, SEASON_ISO_CONFIG } from 'api/api'
import eachOfLimit                from 'async/eachOfLimit'
import { _seasons_isometry } from 'state/store.season'
import { getIsoData } from 'state/jotai.isometryDataHooks'

export const PreloadSeasons_Frames = () => {

  const { pin, pid } = useGet(active_project)
  
  const type   = useGet(_bootstrap).type
  const cache  = useGet(_seasons_isometry).status
  const d  = useGet(_seasons_isometry).config
  const poly   = type === 'poly'
  const file   = poly ? 'main/Poly/PolyPreloadFrames.jsx' : ''
  const url    = pid ? SEASON_ISO_CONFIG : null
  const config = useFetch({ url:type?url:null, file }).D
  const season  = getFocus(_seasons_isometry, 'season_type')
  const isoConfig = getIsoData("config");

  const activeIsometryFrame   = getIsoData("activeframe");
  
  const setFrames  = setFocus(_seasons_isometry, 'frames')
  const setPercent = setFocus(_seasons_isometry, 'percent')
  const setStatus  = setFocus(_seasons_isometry, 'status')
  const setConfig  = setFocus(_seasons_isometry, 'config')

  const CS = useGet(currentSeason);
  // const setTiny  = setFocus(_seasons_isometry, 'tiny')
  // const setFrame = setFocus(_seasons_isometry, 'activeframe')
  // const setMode  = setFocus(_seasons_isometry, 'mode')
 
  useEffect(()=>{
    const seasonType = season?.[pin]?.[pid]?.[activeIsometryFrame]?.type
 
    if (config && activeIsometryFrame && seasonType && !['FETCHING','DONE'].includes(cache?.[pin]?.[pid]?.[activeIsometryFrame]?.[seasonType]) ) {
        const x = config[pin]
        const ARR = [pin, x]
        console.log()
        if(!Object.keys(x.SEASONS?.[activeIsometryFrame]?.[seasonType]).length) return
        const PATH = x.SEASONS?.[activeIsometryFrame]?.[seasonType].PNG_PATH
        const NUM = x.SEASONS?.[activeIsometryFrame]?.[seasonType].IMAGE_NUM/30
        const INI = x.SEASONS?.[activeIsometryFrame]?.[seasonType].FIRST_IMAGE
        const SNP = x.SEASONS?.[activeIsometryFrame]?.[seasonType].IMAGE_SNAPFRAMES
        const EXT = x.FILE_EXT
        let index = 0;
        const FNC = x => `${ISO_FRAMES}${PATH}/${x + INI}.${EXT}`
        const COL = array(NUM, FNC, SNP)

        setConfig ( a => a?.map((d,i)=> fn(d,i,pin,pid,x)) )
        eachOfLimit(COL, 5, 
          ( val, idx, callback ) => {
            const IMG     = preloadImage(val)
            const STATUS  = ( idx !== NUM - 1 ) ? 'FETCHING' : 'DONE'
            const PERCENT = ( idx * ( 100 / NUM ) ).toFixed() 
            
            for(let i=0; i<30; i++) {
              ARR[index] = { [INI+index]:IMG }
              index++
            }
            
            setFrames ( a => a?.map((d,i)=> fn(d,i,pin,pid,ARR, seasonType, activeIsometryFrame )) )
            setPercent( a => a?.map((d,i)=>fn(d,i,pin,pid,PERCENT, seasonType, activeIsometryFrame )) )
            setStatus ( a => a?.map((d,i)=>fn(d,i,pin,pid,STATUS, seasonType, activeIsometryFrame  )) )
            IMG.onload = ( ) => callback()
          },
          () => {
            // console.log( 'Done preloading frames for', pin, pid )
          }
        )
    }
  },[config, pin, pid, season?.[pin]?.[pid]?.[activeIsometryFrame]?.type, activeIsometryFrame])
  return null
}
const fn = (x,y,z,v,w,s,t) => (y===z) ? s ? { [v] : { [t]: {...x[v][t], [s] : w}} } : { [v] : w} : x