import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Address from "./Address";
import Buttons from "./Buttons";
import CTA from "./CTA";
import Description from "./Description";
import Downloads from "./Downloads";
import { FloorplansModalButton } from "./FloorplansModal";
import Footer from "./Footer";
import Gallery from "./Gallery";
import Highlights from "./Highlights";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import Price from "./Price";
import Properties from "./Properties";
import RelatedUnits from "./RelatedUnits";
import SignUpForm from "./SignUpForm";
import Statistics from "./Statistics";
import { get } from 'components/Favorites/Favorites.Utilities'
import Unit from "./Unit.model";
import { useGet } from "state/jotai";
import { kioskMode } from "state/store.global";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { ImgWrapper } from "components/ImgContainer/ImgWrapper";

const UnitPageDesktop = ({ unit }) => {
   //console.log(unit,"unit");
  const KioskMode  = useGet(kioskMode);
  const unitId     = unit.getId();
  const images     = unit.getImages();
  const status     = unit.getStatus();
  const highlights = useUnitHighlight(unit);
  const isRent     = unit.isRent();
  const price      = unit.getPrice();
  const Pris       = unit.getPris();
  const properties = unit.getProperties();
  const descTitle  = unit.getDescriptionTitle();

  const availableUnits = unit?.data?.Data?.UnitStatusID?.Value  === 10;
  const availablePris  = unit?.data?.Data?.Pris_Tekst_DK?.Value === "";

  const description     = unit.getDescription();
  const areaDescTitle   = unit.getAreaDescriptionTitle();
  const areaDescription = unit.getAreaDescription();

  const unitImage = images[2]?.url || "";
  const areaImage = images[images.length - 1]?.url || "";

 const filteredProperties = availablePris ? properties :
  properties.filter(property=>property?.order !==20)


  return (
    <Box>
      <Grid container alignItems = "stretch">
        {/* gallery */}
        <Grid
          item
            // md={8.5}
          sx={{ backgroundColor: "#dddddd", position: "relative", width: { 
            xs: '70%',
            xl: 'calc(100% - 426px)'} }}
        >
          <Gallery images = {images.slice(1)} hideThumbs fullHeight />
          <Box     sx     = {{ minWidth: 421, position: "absolute", bottom: 0, left: 0, zIndex: 5, }} >
          <Buttons unitId = {unitId} />
          </Box>
          <Box                   sx   = {{ position: "absolute", bottom: 0, right: 0, zIndex: 5 }}>
          <FloorplansModalButton unit = {unit} />
          </Box>
        </Grid>

        {/* overview */}
        <Grid item 
          // md={3.5}
        sx={{ width: {
          xs: '30%',
          xl: '426px'} }}
        >
          {/* status bar */}
          <Box
            sx={{
              py             : 2,
              px             : 2.5,
              backgroundColor: `${status.color}e6`,
              color          : "#ffffff",
              fontWeight     : "bold",
            }}
          >
            {status.label}
          </Box>

          <OpenHouse />

          <Box sx = {{ px: 2.5, pb: 5, mt: 3 }}>
            <Address>
              <p>
              {unit.getAddress()},
              </p>
              <span>
              {unit.getTownZipCode()}
              </span>
            </Address>

            <Box        sx    = {{ mt: highlights.length ? 7 : 0, mx: "auto", maxWidth: { lg: '100%' }, px: 0.5 }}>
            <Highlights items = {highlights} />
            </Box>

            <Box sx = {{ mt: 6, textAlign: "center" }}>
             <Price
              label = {isRent ? `${ get(unit.data,'LejelejlighedsText') }` : "Kontatpris"}
              value = {price}
              />
            </Box>

            <Box sx  = {{ mt: 4 }}>
            <CTA clr = {clr}/>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container alignItems = "stretch">
        {/* properties */}
        <Grid item md={3.5} sx={{
          borderRight: "1px solid rgba(0,0,0,0.05)",
        }}>
          <Box
            sx={{
              px: { xl: 6, lg: 5, md: 3 },
              py: { xl: 7, lg: 6, md: 5 },
              my: 9
            }}
          >
            <Properties groups = {filteredProperties} />
            { !KioskMode && <Downloads />}
          </Box>
        </Grid>

        {/* content */}
        <Grid item md      = {8.5}>
        <Grid container sx = {{ backgroundColor: "#f3f3f3" }}>
            <Grid item 
              // xs={7.065}
            sx={{ width:{ 
              xs: '57.7%',
              xl: 'calc(100% - 426px)'} }}
            >
              <Box p = {6} my = {9}>
                <Description
                  header       = {descTitle}
                  content      = {description}
                  overlayColor = "#f3f3f3"
                />
              </Box>
            </Grid>
            <Grid item 
              // xs={4.925}
            sx={{ display: 'flex', width:{ 
              xs: '42.3%',
              xl: '426px'} }}>
              {unitImage && (
                 <ImgWrapper img={unitImage} style={{ height   : "100%", objectFit: "cover", }} />
                // <Box
                //   component = "img"
                //   src       = {unitImage}
                //   sx        = {{
                //     height   : "100%",
                //     objectFit: "cover",
                //   }}
                // />
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item 
              // xs={4.925}
            sx = {{ display: 'flex', width:{ xs: '42.3%', xl: '426px'} }}>
              {areaImage && (
                <ImgWrapper img={areaImage} style={{ height   : "100%", objectFit: "cover", }} />
                // <Box
                //   component = "img"
                //   src       = {areaImage}
                //   sx        = {{
                //     height   : "100%",
                //     objectFit: "cover",
                //   }}
                // />
              )}
            </Grid>
            <Grid item 
              // xs={7.065}
            sx={{ width:{ 
              xs: '57.7%',
              xl: 'calc(100% - 426px)'} }}
            >
              <Box         p      = {6} mt                  = {5} mb = {10}>
              <Description header = {areaDescTitle} content = {areaDescription} />
              </Box>
            </Grid>
          </Grid>

          <Box        mb   = {6}>
          <Statistics unit = {unit} />
          </Box>

          <Box          mb   = {6}>
          <RelatedUnits unit = {unit} />
          </Box>
        </Grid>
      </Grid>

      {/* FOOTER */}
      <Grid       container sx = {{ backgroundColor: "secondary.main" }}>
      <Grid       item md      = {6} sx = {{ backgroundColor: "secondary.main" }}>
      <SignUpForm unit         = {unit} />
        </Grid>
        <Grid item md = {6} sx = {{ backgroundColor: "#ffffff", pb: 5 }}>
          <Footer />
        </Grid>
        <PoweredBy clr = {clr.sub_text_color}/>
      </Grid>
    </Box>
  );
};

UnitPageDesktop.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default UnitPageDesktop;
