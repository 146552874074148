import styled from 'styled-components/macro'
import { useBreak } from 'hooks/useBreak'
import { kioskMode } from 'state/store.global';
import { useGet } from 'state/jotai';

export const Center = ({ point }) => {
  const currentLocation = window.location.href;
  const isKisokMode = useGet(kioskMode);
  const isMobile = useBreak('md_dn')
  const p = {
    p_moblie: isKisokMode ? point?.KIOSK_MOBILE : point?.MOBILE,
    d_desktop: isKisokMode ? point?.KIOSK_DESKTOP : point?.MOBILE
  }
  return (
    <>
      <StyledCenter 
        point={isMobile ? p?.p_moblie : p?.d_desktop }
        id='center'
      />
    </>
  )

}

const StyledCenter = styled.div`
  position: absolute;
  left: ${ ({point}) => point?.[0] ?? '' }px;
  top: ${ ({point}) => point?.[1] ?? '' }px;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius:150%;
`