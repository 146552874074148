import styled from 'styled-components';
import { BREAK_ } from 'constants';
import { Icon } from 'assets/Icons_';
import { useBreak } from 'hooks/useBreak';
import { _seasons_isometry } from 'state/store.season';
import { getIsoData, getSeasonIsoData, setSeasonIsoData } from 'state/jotai.isometryDataHooks';
import { active_project, iso_free, iso_isrotating } from 'state/store.global';
import { getFocus, useGet, useSet } from 'state/jotai';
import { _project } from 'state/store.projects';

const SelectWeather = () => {
    const activeIsometryFrame = getIsoData("activeframe");
    const setRmode    = useSet(iso_free)
    const setIsrotate = useSet(iso_isrotating)
    const weather     = getSeasonIsoData("season_type");
    const weatherType = weather?.[activeIsometryFrame]?.type;
    const setWeather  = setSeasonIsoData("season_type");
    const { pin, pid } = useGet(active_project) 
    const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

    const handleWeatherSelect = (w) => {        
        setWeather({...weather, [activeIsometryFrame]: {type: w}});
    };

    return (
        <Wrap>
            {/* Wrapping div with flex display */}
            <ButtonContainer>
                {/* Weather button 1 */}
                <WeatherButtonContainer>
                    <WeatherButtons
                        onClick    = {() => handleWeatherSelect('spring')}
                        isSelected = {weatherType?.toLowerCase()  === 'spring'}
                    >
                        <Icon icon     = 'spring' color = {weatherType?.toLowerCase() === 'spring' ? '#FFF' : '#000'} />
                        <Text selected = {weatherType?.toLowerCase()  === 'spring'}>{buttonsLabel?.filter( btn => btn.UILabelProperty === "Efterar")[0]?.Label}</Text>
                    </WeatherButtons>
                </WeatherButtonContainer>

                {/* Weather button 2 */}
                <WeatherButtonContainer>
                    <WeatherButtons
                        onClick    = {() => handleWeatherSelect('summer')}
                        isSelected = {weatherType?.toLowerCase()  === 'summer'}
                    >
                        <Icon icon     = 'sun' color = {weatherType?.toLowerCase()  === 'summer' ? '#FFF' : '#000'} />
                        <Text selected = {weatherType?.toLowerCase()  === 'summer'}>{buttonsLabel?.filter( btn => btn.UILabelProperty === "Sommer")[0]?.Label}</Text>
                    </WeatherButtons>
                </WeatherButtonContainer>

                {/* Weather button 3 */}
                <WeatherButtonContainer>
                    <WeatherButtons
                        onClick    = {() => handleWeatherSelect('fall')}
                        isSelected = {weatherType?.toLowerCase()  === 'fall'}
                    >
                        <Icon icon     = 'cloud' color = {weatherType?.toLowerCase()  === 'fall' ? '#FFF' : '#000'} />
                        <Text selected = {weatherType?.toLowerCase()  === 'fall'}>{buttonsLabel?.filter( btn => btn.UILabelProperty === "Forar")[0]?.Label}</Text>
                    </WeatherButtons>
                </WeatherButtonContainer>

                {/* Weather button 4 */}
                <WeatherButtonContainer>
                    <WeatherButtons
                        onClick    = {() => handleWeatherSelect('winter')}
                        isSelected = {weatherType?.toLowerCase()  === 'winter'}
                    >
                        <Icon icon     = 'winter' color = {weatherType?.toLowerCase()  === 'winter' ? '#FFF' : '#000'} />
                        <Text selected = {weatherType?.toLowerCase()  === 'winter'}>{buttonsLabel?.filter( btn => btn.UILabelProperty === "Vinter")[0]?.Label}</Text>
                    </WeatherButtons>
                </WeatherButtonContainer>
            </ButtonContainer>
        </Wrap>
    );
};

export default SelectWeather;

const ButtonContainer = styled.div`
    display       : flex;
    flex-direction: column;
    gap           : 8px;
`;
const WeatherButtonContainer = styled.div`
    display: flex;
    gap    : 8px;
`;

const Wrap = styled.div`
    position   : absolute;
    top: calc(50% - 38px);
    transform: translate(0, -50%);
    left       : 48px;
    display    : flex;
    align-items: center;
    gap        : 6px;
    font-size  : 15px;
    width      : 32px;
    height     : 32px;
    cursor     : pointer;
    line-height: 1;
    ${BREAK_.md_dn} {
        font-size: 17px;
    }
`;
const WeatherButtons = styled.button`
    display    : flex;
    width      : 800%;
    min-height : 40px;
    gap        : 8px;
    cursor     : pointer;
    padding    :  0 16px;
    align-items: center;
    border    : 2px solid ${(props) => (props.isSelected ? '#EFEFEF' : '#D1D1D1')};
    background: ${(props) => (props.isSelected ? '#A9A9A9' : '#EFEFEF')};
`;

const Text = styled.div`
    white-space: nowrap;
    font-family: 'Cerebri Sans';
    font-size  : 16px;
    font-weight: 400px;
    color      : ${(props) => (props.selected ? '#FFF' : '#000')};
`;
