import { useEffect, useMemo }                     from 'react'
import { _bootstrap }                             from 'state/store.bootstrap'
import { _project }                               from 'state/store.projects'
import { useFetch }                               from 'hooks/fetch/useFetch'
import { useFetch_Units }                         from 'hooks/fetch/useFetch_Units'
import { useGet, setFocus, useAtom, get }         from 'state/jotai'
import { PROJECT, PAGE, DOWNLOADS }               from 'api/api'
import { initialsort as isr }                     from 'utilities/utility.sort'
import { useTranslation }                         from 'react-i18next'
import { current_language, active_unit }          from 'state/store.global'

export const ProjectData_Mono = () => {

  const { i18n:{language} } = useTranslation()

  const type = useGet(_bootstrap).type
  const pid  = useGet(_bootstrap).projectids?.[0] || null

  const [activeUnits, setActiveUnits] = useAtom(active_unit)
  const units                         = get(_project, 'units', 'Units', false)

  const mono = type === 'mono'
  const file = mono ? 'main/ProjectData.jsx' : ''

  const url1 = pid ? PROJECT(pid,language)    : null
  const url2 = pid ? DOWNLOADS(pid)  : null
  const url3 = pid ? PAGE(pid,1080,language)  : null
  
  const project    = useFetch({ url:mono?url1:null, file }).D
  const downloads1 = useFetch({ url:mono?url2:null, file }).D
  const gallery    = useFetch({ url:mono?url3:null, file }).D

  if(Object.keys(activeUnits).length) {
    setActiveUnits(units.filter( u => u.UnitID === activeUnits.UnitID)[0])
  }

  const [, setCurrent_language] = useAtom(current_language);
  
  useFetch_Units({ pid:mono?pid:null, pin:0 })

  const setCardtext          = setFocus(_project, 'cardtext')
  const setStatustypes       = setFocus(_project, 'statustypes')
  const setUnittypes         = setFocus(_project, 'unittypes')
  const setSorteroptions     = setFocus(_project, 'sorteroptions')
  const setFilteroptions     = setFocus(_project, 'filteroptions')
  const setDefaultSort       = setFocus(_project, 'activesorting')
  const setOpenhouse         = setFocus(_project, 'openhouse')
  const setGallery           = setFocus(_project, 'gallery')
  const setDownloads         = setFocus(_project, 'downloads')
  const setButtonsLabel      = setFocus(_project, 'buttonsLabel')
  const setUnitStatus        = setFocus(_project, 'unitStatuses')
  const setHighlights        = setFocus(_project, 'Highlights')
  const setContactProperties = setFocus(_project, 'ContactProperties')
  const setIconLookup        = setFocus(_project, 'IconLookup')
  const setMenuItems         = setFocus(_project, 'menuItems')
  
  useEffect(()=>{
    if ( mono && project && downloads1 && gallery ) {
      setCardtext     ([{ [pid] : project.config.ShowInListView   }])
      setStatustypes  ([{ [pid] : project.config.UnitStatuses     }])
      setUnittypes    ([{ [pid] : project.config.SaleAndUnitTypes }])
      setFilteroptions([{ [pid] : project.config.Filters          }])
      setSorteroptions([{ [pid] : project.config.SortOrders       }])
      setDefaultSort  ([{ [pid] : isr(project.config.SortOrders)  }])
      setOpenhouse    ([{ [pid] : project.OpenHouse               }])
      setGallery      ([{ [pid] : gallery.Components[1]           }])
      setDownloads    ([{ [pid] : downloads1                      }])
      setButtonsLabel ([{ [pid] : project.LabelProperties         }])
      setUnitStatus   ([{ [pid] : project.config.UnitStatuses     }])
      setHighlights   ([{ [pid] : project.Highlights              }])
      setContactProperties([{ [pid] : project.ContactProperties   }])
      setIconLookup   ([{ [pid] : project.IconLookupProperties    }])
      setMenuItems    ([{ [pid] : project.MenuProperties          }])
      setCurrent_language(project.Language)
    }
  },[mono, project, downloads1, gallery])

  return (
    <></>
  );
}