import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { cacheImg } from "state/store.global";

export const useCachedImages = (imageUrl) => {
  const [cacheImgState, setCacheImg] = useAtom(cacheImg);

  useEffect(() => {
    const loadImage = async () => {
      try {
        if (!cacheImgState[imageUrl]) {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        // const img = response.url
        // const base64Image = await convertBlobToBase64(blob);
        // const mimeType = response.headers.get('content-type');
    
        // Check again if the image is in the cache to avoid duplicates
      
        const imgElement = document.createElement('img');
        imgElement.src = URL.createObjectURL(blob);

          // Update the image cache using Jotai
          // setCacheImg((prevCache) => ({
          //   ...prevCache,
          //   [imageUrl]: { base64Image, mimeType },
          // }));
          setCacheImg((prevCache) => ({
            ...prevCache,
            [imageUrl]: imgElement,
          }));
        }
      } catch (error) {
        console.error('Error loading image:', error);
      }
    };
    loadImage();

    return () => {
      // Clean up any resources if needed
      // if (cacheImgState[imageUrl]) {
      //   const imgElement = cacheImgState[imageUrl];
        
      //   // Check if the src starts with 'blob:' before revoking the object URL
      //   if (imgElement.src && imgElement.src.startsWith('blob:')) {
      //     URL.revokeObjectURL(imgElement.src);
      //   }
      // }
    };
  }, [imageUrl]);

  return cacheImgState[imageUrl] || null;
};

// Helper function to convert a Blob to base64
const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
