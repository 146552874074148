import { useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useUnitById } from './hooks'
import UnitPageDesktop from './UnitPageDesktop'
import UnitPageMobile from './UnitPageMobile'
import { active_unit } from 'state/store.global'
import { useAtom } from 'jotai'
import { useEffect } from 'react'

function UnitPage({ unitId }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

  const setActiveUnit = useAtom(active_unit)[1]

  const unit = useUnitById(unitId)

  useEffect(()=>{
    if ( unit.getId() ) {
      setActiveUnit(unit.data)
    }
    return () => {};
  },[unit.getId()])

  
  if (!unit.getId()) {
    return <></>
  }
  
  return isMobile ? (
    <UnitPageMobile unit={unit} />
  ) : (
    <UnitPageDesktop unit={unit} />
  )

}

UnitPage.propTypes = {
  unitId: PropTypes.number.isRequired,
}

export default UnitPage
