import { useRef, useEffect, useState } from "react";

import { AnimatePresence } from "framer-motion";
import useMeasure from "react-use-measure";
import { _project } from "state/store.projects";
import { _isometry } from "state/store.isometry";
import { active_project, currentSeason, kioskMode, seasonMode, weaterMode } from "state/store.global";
import { iso_free } from "state/store.global";
import { iso_isrotating } from "state/store.global";
import { getFocus, setFocus, useGet } from "state/jotai";
import { getIsoData, getSeasonIsoData, setIsoData } from "state/jotai.isometryDataHooks";
import { getProjectData } from "state/jotai.projectDataHooks";

import { useBreak } from "hooks/useBreak";

import { Space } from "./Isometry.style";
import { Cursor } from "./Isometry.Cursor";
import { createZppSetup } from "./Isometry.ZoomPanPinch";
import { Canvas } from "./Isometry.Canvas";
import { Center } from "./Isometry.Center";
import { Tiny } from "./Isometry.Tiny";
import { Svg } from "./Isometry.Svg";
import { Controls } from "./Isometry.Controls";
import { Measure } from "./Isometry.Measure";
import { Recenter } from "./isometry.Recenter";
import { Loading2 } from "components/Loading2/Loading2";
import { ZoomPanPinch } from "components/ZoomPanPinch/ZoomPanPinch";
import { Compas } from "components/Compas/Compas";
import { ColorLegend } from "components/ColorLegend/ColorLegend";
import { NoSnapSlider } from "components/IsometryControls/NoSnapSlider";
import { Play } from "components/IsometryRotation/Play";
import { SnapOnRotationEnd } from "components/IsometrySnapOnRotationEnd/SnapOnRotationEnd";
import { Status } from "components/StatusPopup/StatusPopup";
import { Mode360 } from "components/IsometryRotation/360";
import { drawer_animation_done } from "state/store.global";
import { Back } from "./Isometry.Back";
import { _seasons_isometry } from "state/store.season";
import { SeasonCanvas } from "./Isometry.SeasonCanvas";
import SelectWeather from "components/SunDiagram/SelectWeather";
import { SeasonsNoSnapSlider } from "components/IsometryControls/SeasonsNoSnapSlider";

export const SeasonIsometry = () => {
  const isMobile          = useBreak('md_dn');
  const [measure, bounds] = useMeasure();
  const [frame, setFrame] = useState();
  
  const { pin, pid }        = useGet(active_project);
  const isKisokMode         = useGet(kioskMode);
  const activeIsometryFrame = getIsoData("activeframe");


  const season     = getFocus(_seasons_isometry, 'season_type');
  const seasonType = season?.[pin]?.[pid]?.[activeIsometryFrame]?.type;

  const mode    = true;
  const tiny    = getSeasonIsoData("tiny", "src");
  const frames  = getSeasonIsoData("frames")?.[activeIsometryFrame]?.[seasonType];
  const percent = getSeasonIsoData("percent")?.[activeIsometryFrame]?.[seasonType];
  const status  = getSeasonIsoData("status")?.[activeIsometryFrame]?.[seasonType];
  const config  = getSeasonIsoData("config")?.SEASONS?.[activeIsometryFrame]?.[seasonType]

  const svg   = getIsoData("overlays", frame);
  const index = getIsoData("snapindex");
  const units = getProjectData("units", "Units");

  const setIndex = setIsoData("snapindex");
  //   const setFrame = setIsoData("activeframe");
  const setScale       = setFocus(_seasons_isometry, "activescale");
  const setWeatherMode = useGet(weaterMode)

  const zppRef   = useRef();
  const canRef   = useRef();
  const dim      = { W: isKisokMode ? config?.KIOSK_IMAGE_W : config?.IMAGE_W, H: isKisokMode ? config?.KIOSK_IMAGE_H : config?.IMAGE_H };
  const point    = config?.CENTER;
  const zppsetup = createZppSetup(bounds, zppRef, dim, setScale, mode);
  const rmode    = useGet(iso_free);
  const isrotate = useGet(iso_isrotating);
 
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  
  useEffect(() => {
      if(frames?.length) {
          setFrame(+Object.keys(frames[0])?.[0])
      }
  }, [frames]) 
  if (config && pid && pin >= 0)
    return (
      <>
        <Cursor>
          <Space   d   = {25} m = {120} />
          <Measure ref = {measure}>
            <ZoomPanPinch {...{ ref: zppRef, ...zppsetup }}>
              <SeasonCanvas
                {...{ ref: canRef, frame, pid, status, dim, frames, config }}
              />
              <Center {...{ point }} />
            </ZoomPanPinch>
          </Measure>
          <Space d = {180} m = {110} />
          {setWeatherMode === true && !isMobile ? <SelectWeather /> : <></>}
          <Loading2
            {...{
              progress: percent,
              show    : (status !== "DONE" || !units) && frames?.length > 1,
            }}
          />
          <AnimatePresence>
            {rmode && status === "DONE" && Boolean(frame) && (
              <>
                <SeasonsNoSnapSlider
                  {...{ frame, setFrame, config, frames, index, setIndex }}
                />
                {rmode && isrotate && Boolean(frame) && (
                  <Play {...{ frame, setFrame, config, setIndex }} />
                )}
              </>
            )}
          </AnimatePresence>
          <Recenter z = {zppRef} bounds = {bounds} dim = {dim} />
          {/* { !isMobile && <Mode360/> } */}
          {/* { !isMobile && rmode && <Back/> } */}
        </Cursor>
      </>
    );
};
